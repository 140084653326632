<template>
  <div class="w-full h-full">
    <BaseImage
      src="/employers/company-information.png"
      alt="Company Information"
      sizes="720px"
      class="w-full h-full rounded-lg object-cover"
    />
  </div>
</template>
